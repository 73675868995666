

















































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import {
  Getter, Mutation, namespace, State,
} from 'vuex-class';
import Session from '@/models/graphql/Session';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import VideoPlayerComponent from '@/components/video-player/VideoPlayerComponent.vue';
import Poll from '@/models/graphql/Poll';
import MessageGroup from '@/models/graphql/MessageGroup';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import MessageBoxMessages from '@/views/chat/MessageBoxMessages.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import ClientStorage from '@/utils/ClientStore';
import GroupType from '@/utils/enums/chat/GroupType';
import EntityType from '@/utils/enums/EntityType';
import ChatModuleHelper from '@/components/chat/ChatModuleHelper.vue';
import GUUID from '@/utils/GUUID';
import SessionCmsQaComponent from '@/components/session-cms/SessionCmsQaComponent.vue';
import GenericEvent from '@/utils/types/GenericEvent';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import SessionLiveStreamConfigComponent
  from '@/components/session-cms/SessionLiveStreamConfigComponent.vue';
import SessionCmsPageHeaderComponent
  from '@/components/session-cms/SessionCmsPageHeaderComponent.vue';
import Message from '@/models/graphql/Message';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import MessageQnaRepository from '@/repositories/MessageQnaRepository';
import PollComponent from '@/components/poll/PollComponent.vue';
import SessionSettingsComponent from '@/components/speaker-cms/SessionSettingsComponent.vue';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import SessionSettingType from '@/utils/enums/SessionSettingType';
import { Location } from 'vue-router';
import { TabType } from '@/utils/types/TabType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';

const notificationStore = namespace('NotificationStore');

@Component({
  components: {
    IllustrationComponent,
    SessionSettingsComponent,
    PollComponent,
    SessionCmsPageHeaderComponent,
    SessionLiveStreamConfigComponent,
    SessionCmsQaComponent,
    VideoPlayerComponent,
    MessageBoxMessages,
    FontAwesomeComponent,
    ButtonComponent,
  },
})
export default class SessionCmsBoardWidget extends mixins(VueBaseWidget,
  VueRegisterStoreWidget,
  ChatModuleHelper,
  VueBaseNotify) {
  @notificationStore.Mutation
  unsubscribeGenericEventPointer!: (channel: string) => void;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  protected storeContext = 'LiveSessionChatStore';

  @Prop({
    required: false,
    default: null,
  })
  private entityCode!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private data!: Session[];

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @State
  private sessionSelectedTab!: TabType;

  @Mutation
  private setSessionSelectedTab!: (tab: TabType) => void;

  private session: Session | null = null;

  private newPoll: Poll | null = null;

  private hasNewQuestion = false;

  private tempGroupId = GUUID.uuidv4();

  private messageGroup: MessageGroup | null = null;

  private lastViewedQuestionConstant = 'last-viewed-questions';

  private parentSessionUid: string | null = null;

  private FeatureKeys = FeatureKeys;

  private IllustrationType = IllustrationType;

  private get selectedGroup(): MessageGroup {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/selectedGroup`];
  }

  private get uid(): string | null {
    let { entityCode } = this;
    if (this.payload && 'entityCode' in this.payload && this.payload.entityCode) {
      entityCode = this.payload.entityCode as string;
    }
    let r = entityCode;
    if (entityCode) {
      const matches = entityCode.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          r = r.replaceAll(m, this.$route.params[prop]);
        });
      }
      return r;
    }
    return this.entityCode;
  }

  private get showLiveSession(): boolean {
    if (this.session) {
      return this.isLive || this.isRecorded;
    }
    return false;
  }

  private get canShowSidePanel(): boolean {
    return this.isChatFeatureEnabled || this.isPollTabEnabled || this.isQaTabEnabled;
  }

  private get isLive(): boolean {
    return this.session ? !!(this.session.videoLiveEnabled && this.session.videoType === 'live') : false;
  }

  private get isRecorded(): boolean {
    return this.session
      ? !!(this.session.videoArchiveEnabled
        && this.session.videoType === 'recorded')
      : false;
  }

  private get isChatFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE).enabled;
  }

  private get isQaTabEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_LIVE_QNA_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_LIVE_QNA_FEATURE).enabled;
  }

  private get isPollTabEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_LIVE_POLLS_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_LIVE_POLLS_FEATURE).enabled;
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.SESSION_SETTINGS_CHANGE,
      NotificationEventType.SESSION_VIDEO_CHANGE,
    ];
    if (this.payload && this.uid) {
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'session',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SESSION_FILTER,
            value: { uid: this.uid },
          },
        }),
        fragmentName: 'sessionForLiveVideoFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing params in payload to identify the session for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }

  beforeDestroy(): void {
    this.unsubscribeGenericEventPointer(`qa-channel-${this.uid}`);
    if (this.session) {
      this.unsubscribeGenericEventPointer(`session-settings-${this.session.uid}`);
    }
  }

  @Watch('data')
  loadSession(): void {
    if (this.data && this.data.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.session = this.data[0];
      if (this.session.moderators.findIndex((moderator) => moderator.uid === this.authUser.uid) === -1) {
        this.$router.replace({ name: 'forbidden' });
        return;
      }
      if (this.session.parentSession) {
        this.parentSessionUid = this.session.parentSession.uid;
      }
      this.initSelectedTab();
      this.genericEvent({ channel: `qa-channel-${this.uid}` });
      this.genericEvent({ channel: `session-settings-${this.session.uid}` });
    }
  }

  protected notificationCallback(event: GenericEvent): void {
    if (this.sessionSelectedTab !== 'qna'
      && event
      && event.type === NotificationEventType.QA_NEW_QUESTION) {
      this.hasNewQuestion = true;
    }

    const extraData = JSON.parse(event.extra);
    if (event
      && event.type === NotificationEventType.SESSION_SETTINGS_CHANGE
      && this.session && event.entityId === this.session.uid
      && extraData.settingType) {
      switch (extraData.settingType) {
        case SessionSettingType.CHAT:
          this.session.showLiveChat = extraData.isActivated;
          if (this.session.showLiveChat) {
            this.loadChat();
          }
          break;
        case SessionSettingType.QNA:
          this.session.showQNA = extraData.isActivated;
          break;
        case SessionSettingType.POLLS:
          this.session.showPolls = extraData.isActivated;
          break;
        default:
          break;
      }
    }

    if (event
      && event.type === NotificationEventType.SESSION_VIDEO_CHANGE
      && this.session && event.entityId === this.session.uid) {
      this.session.videoEnabled = !extraData.disabled;
      this.session.videoEnabledStartTime = extraData.videoEnabledStartTime;
      this.session.videoEnabledEndTime = extraData.videoEnabledEndTime;
      if (extraData.isLive) {
        this.session.videoLiveEnabled = true;
        this.session.videoArchiveEnabled = false;
        this.session.videoLiveEmbedEndpoint = extraData.videoUrl;
        this.session.videoType = 'live';
      } else if (extraData.isRecorded) {
        this.session.videoLiveEnabled = false;
        this.session.videoArchiveEnabled = true;
        this.session.videoArchiveEmbedEndpoint = extraData.videoUrl;
        this.session.videoType = 'recorded';
      }
    }
  }

  @Watch('isDesktop')
  private initSelectedTab(): void {
    const availableTabs: TabType[] = [];
    if (this.isChatFeatureEnabled) {
      availableTabs.push('chat');
      this.loadChat();
      if (this.isQaTabEnabled) {
        this.getQaNotification();
      }
    }
    if (this.isQaTabEnabled) {
      availableTabs.push('qna');
    }
    if (this.isPollTabEnabled) {
      availableTabs.push('polls');
      if (!this.isChatFeatureEnabled && this.isQaTabEnabled) {
        this.getQaNotification();
      }
    }
    if (!this.isDesktop) {
      availableTabs.push('settings');
    }

    // This is to manage tab navigation by URL on page load
    const selectedTab = this.$route.query.sessionSelectedTab as TabType;
    if (selectedTab && availableTabs.includes(selectedTab)) {
      this.setNewSelectedTab(selectedTab, true);
    } else if (availableTabs.length > 0) {
      this.setNewSelectedTab(availableTabs[0], true);
    } else {
      this.setNewSelectedTab(null, true);
    }
  }

  private setNewSelectedTab(tab: TabType, replace = false): void {
    if (tab === null) {
      delete this.$route.query.sessionSelectedTab;
    }
    if (this.sessionSelectedTab !== tab) {
      this.setSessionSelectedTab(tab);
      const url = this.$router.resolve({
        ...this.$route,
        query: {
          ...(this.$route.query || {}),
          ...(this.sessionSelectedTab ? { sessionSelectedTab: this.sessionSelectedTab } : {}),
        },
      } as Location).href;
      if (replace) {
        window.history.replaceState(
          null,
          '',
          url,
        );
      } else {
        window.history.pushState(
          null,
          '',
          url,
        );
      }
    }
  }

  private getQaNotification(): void {
    if (this.session) {
      const messageQnaRepo = new MessageQnaRepository();
      messageQnaRepo.filter({
        operationName: 'LoadQnAMessages',
        fragmentName: 'messageUidFragment',
        definition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: {
              group: {
                target: {
                  uid: this.session.uid,
                },
                type: 'QNA',
              },
              // eslint-disable-next-line @typescript-eslint/camelcase
              userDeleted_not: true,
              parentMessage: null,
              type: 'QNA',
            },
            type: GqlEntityFilterType.MESSAGE_FILTER,
          },
          orderBy: {
            value: ['senttimestamp_desc'],
            type: GqlEntityOrderingType.MESSAGE_ORDERING,
          },
          first: 1,
          offset: 0,
        }),
      })
        .then((result: Message[]) => {
          if (result && result.length > 0) {
            const question = result[0];
            const jsonData = ClientStorage.getItem(this.lastViewedQuestionConstant);
            const lastViewedQuestions: {
              sessionUid: string;
              messageUid: string;
            }[] | null = jsonData
              ? JSON.parse(jsonData)
              : null;
            if (lastViewedQuestions && Object.entries(lastViewedQuestions).length > 0) {
              const lastViewedQuestionIndex = lastViewedQuestions
                .findIndex((data: {
                  sessionUid: string;
                  messageUid: string;
                }) => data.sessionUid === this.session?.uid);
              if (lastViewedQuestionIndex >= 0) {
                this.hasNewQuestion = question.uid !== lastViewedQuestions[lastViewedQuestionIndex].messageUid;
              } else {
                this.hasNewQuestion = true;
              }
            } else {
              this.hasNewQuestion = true;
            }
          }
        });
    }
  }

  private onQaTabClick(): void {
    if (this.session) {
      const messageQnaRepo = new MessageQnaRepository();
      messageQnaRepo.filter({
        operationName: 'MessagesAll',
        fragmentName: 'messageUidFragment',
        definition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: {
              group: {
                target: {
                  uid: this.session.uid,
                },
                type: 'QNA',
              },
              // eslint-disable-next-line @typescript-eslint/camelcase
              userDeleted_not: true,
              parentMessage: null,
              type: 'QNA',
            },
            type: GqlEntityFilterType.MESSAGE_FILTER,
          },
          orderBy: {
            value: ['senttimestamp_desc'],
            type: GqlEntityOrderingType.MESSAGE_ORDERING,
          },
          first: 1,
          offset: 0,
        }),
      })
        .then((result: Message[]) => {
          if (result && result.length > 0) {
            const question = result[0];
            const jsonData = ClientStorage.getItem(this.lastViewedQuestionConstant);
            const lastViewedQuestions: {
              sessionUid: string;
              messageUid: string;
            }[] | null = jsonData
              ? JSON.parse(jsonData)
              : null;
            if (lastViewedQuestions && lastViewedQuestions.length) {
              const lastViewedQuestionIndex = lastViewedQuestions
                .findIndex((data: {
                  sessionUid: string;
                  messageUid: string;
                }) => data.sessionUid === this.session?.uid);
              const dataToSave = {
                sessionUid: this.session?.uid || '',
                messageUid: question.uid,
              };
              if (lastViewedQuestionIndex >= 0) {
                lastViewedQuestions[lastViewedQuestionIndex] = dataToSave;
              } else {
                lastViewedQuestions.push(dataToSave);
              }
              ClientStorage.setItem(this.lastViewedQuestionConstant, JSON.stringify(lastViewedQuestions));
            } else {
              const dataToSave = [{
                sessionUid: this.session?.uid,
                messageUid: question.uid,
              }];
              ClientStorage.setItem(this.lastViewedQuestionConstant, JSON.stringify(dataToSave));
            }
          }
        });
    }
    this.hasNewQuestion = false;
    this.setNewSelectedTab('qna');
  }

  private lookupForMessageGroupWithTarget(targetId: string): Promise<MessageGroup | undefined> {
    return this.$store.dispatch(`${this.storeContextPath}/lookupForMessageGroupWithTarget`, targetId);
  }

  private setNewConversation(group: MessageGroup): void {
    return this.$store.commit(`${this.storeContextPath}/setNewConversation`, group);
  }

  private setSelectedGroup(groupId: string): void {
    this.$store.commit(`${this.storeContextPath}/setSelectedGroup`, groupId);
  }

  private changeStatus(status: MessageBoxActions): void {
    this.$store.commit(`${this.storeContextPath}/changeStatus`, status);
  }

  private loadChat(): void {
    if (this.session && this.isChatFeatureEnabled) {
      this.lookupForMessageGroupWithTarget(this.session.uid)
        .then((group) => {
          if (group) {
            this.setSelectedGroup(group.uid);
            this.changeStatus(MessageBoxActions.MESSAGES);
          } else {
            this.messageGroup = MessageGroup.hydrate({
              uid: this.tempGroupId,
              tempId: this.tempGroupId,
              groupType: GroupType.GROUP,
              users: this.session?.moderators || [],
              target: {
                ...this.session,
                __typename: EntityType.SESSION,
              },
              messages: [],
              updated: true,
              selected: true,
            });
            this.setNewConversation(this.messageGroup);
            this.setSelectedGroup(this.messageGroup.uid);
            this.changeStatus(MessageBoxActions.MESSAGES);
          }
        });
    }
  }
}
